
import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import { Iobject } from '@mobro/libs/es/type';
import { MoDate } from '@mobro/libs';
import { proxyImageUrl } from '@/libs/utils';

@Component({
    components: {},
})
export default class MorningPicture extends Vue {
    @Prop() formData!: Iobject;

    @Model('input', { type: Boolean }) readonly value!: boolean;

    showShadowBox = false;

    // 计算属性用于同步 localValue 到 el-dialog 的 visible 属性
    get showPosterBox() {
        return this.value;
    }

    set showPosterBox(newValue) {
        this.$emit('input', newValue); // 当对话框状态改变时发出 input 事件通知父组件
    }

    positionValue = {
        horizontalPercent: 0,
        verticalPercent: 0,
        scalePercent: 100,
        horizontalPixel: 0,
        verticalPixel: 0,
    };

    onHorizontalChange(value: number) {
        this.positionValue.horizontalPercent = value;
    }
    onVerticalChange(value: number) {
        this.positionValue.verticalPercent = value;
    }
    onScaleChange(value: number) {
        this.positionValue.scalePercent = value;
    }
    onRestPositionValue() {
        this.positionValue = {
            horizontalPercent: 0,
            verticalPercent: 0,
            scalePercent: 100,
            horizontalPixel: 0,
            verticalPixel: 0,
        };
    }

    get imagePosition() {
        return `width: 100%; position: absolute; left: ${this.positionValue.horizontalPercent}%; top: ${
            this.positionValue.verticalPercent
        }%; transform-origin:left top; transform: translate(${this.positionValue.horizontalPixel}px, ${
            this.positionValue.verticalPixel
        }px) scale(${this.positionValue.scalePercent / 100})`;
    }

    get getPicture() {
        return proxyImageUrl(this.formData.morning);
    }

    get dateMorningArr(): string[] {
        const o = MoDate.getTimeQuota(new Date(this.formData.articleName));
        const a = {
            '01': 'Jan',
            '02': 'Feb',
            '03': 'Mar',
            '04': 'Apr',
            '05': 'May',
            '06': 'Jun',
            '07': 'Jul',
            '08': 'Aug',
            '09': 'Sep',
            '10': 'Oct',
            '11': 'Nov',
            '12': 'Dec',
        };
        return [
            MoDate.format2('DD', o),
            a[MoDate.format2('MM', o) as keyof typeof a],
            MoDate.format2('YYYY', o),
            MoDate.format2('星期W', o),
        ];
    }
}
